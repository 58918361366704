//
// user.scss
// Use this to write your custom SCSS
//

@font-face {
  font-family: "Avenir Medium";
  font-style: normal;
  src: url("../../assets/fonts/Avenir-Medium.woff") format("woff");
}

.title-font {
  font-family: "Avenir Medium", Montserrat, sans-serif;
  font-variant: small-caps;
  text-transform: uppercase;
}
